import React from "react"
import {
  Seo,
  Layout,
  OtherPageWrapper,
  MainWrapper,
  Card,
  SimpleButton,
} from "components"

import {
  MenuSection,
  MenuContent,
  UpperText,
  MenuList,
  FoodAndPriceWrapper,
  OrderButton,
  Food,
} from "./food-items"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Bakery Items" />
    <OtherPageWrapper title="Bakery Items" />
    <MenuSection>
      <MainWrapper>
        <MenuContent>
          <UpperText>
            <span className="red">
              All orders require a minimum of 24 hrs to prepare. When placing
              your order through ChowNow, please select a pickup date 24 hours
              later. Thank you so much for supporting Neighbarista.
            </span>
            <br />
            <br />
            <span>Greetings Neighbors!</span>
            <br />
            <span>
              Every day it’s our pleasure to provide you, our friends with good
              coffee, good food and friendly service.
            </span>
            <h6>
              Our moment is better because of you! We hope you can make someone
              else's moment with a Neighbarista love...
            </h6>
          </UpperText>
          <MenuList>
            <Card title="Cream Puff" />
            <FoodAndPriceWrapper display="flex">
              <div className="middleside">
                <Food title="3 Count" price="1.95 Each" />
                <Food title="6 Count" price="1.75 Each" />
                <Food title="12 Count" price="1.50 Each" />
                <Food title="24+ Count" price="1.25 Each" />
              </div>
            </FoodAndPriceWrapper>
          </MenuList>
          <MenuList>
            <Card title="Mini Tartlets Fruit or Coconut Cream" />
            <FoodAndPriceWrapper display="flex">
              <div className="middleside">
                <Food title="8 for" price="11.95" />
                <Food title="16 for" price="20.95" />
                <Food title="24 for" price="29.95" />
                <Food title="32 for" price="35.95" />
              </div>
            </FoodAndPriceWrapper>
          </MenuList>
          <MenuList>
            <Card title="TAKE-N-BAKE Cinnamon Rolls" />
            <FoodAndPriceWrapper display="flex">
              <div className="middleside">
                <Food title="6 ct" price="12.95" />
              </div>
            </FoodAndPriceWrapper>
          </MenuList>
          <MenuList>
            <Card title="Pies" />
            <FoodAndPriceWrapper display="flex">
              <div className="middleside">
                <Food
                  title="Apple Dutch Crunch, Pumpkin, Cherry"
                  price="16.95"
                />
                <Food title="Chocolate Mousse, Pecan" price="18.95" />
              </div>
            </FoodAndPriceWrapper>
          </MenuList>
          <Card title="Note">
            <p>
              Assorted Pastries are sold based on the amount of guests you are
              looking to feed.
              <br />
              <br />
              Small assortment:  $24.94<br/>
              Feeds up to 10
              <br />
              Medium assortment:  $35.95<br/>
              Feeds up to 15
              <br />
              Large assortment: $44.95<br/>
              Feeds up to 20
            </p>
          </Card>
        </MenuContent>
        <OrderButton>
          <SimpleButton
            height="45px"
            color="var(--white)"
            background="var(--primaryColor)"
            padding="14px 40px"
            href="https://direct.chownow.com/order/27923/locations"
          >
            Order Online
          </SimpleButton>
        </OrderButton>
      </MainWrapper>
    </MenuSection>
  </Layout>
)

export default NotFoundPage
